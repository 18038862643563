import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import styled from 'styled-components';

import { gradStart, offWhite, purple } from 'constants/theme';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  background-color: ${purple};
  color: white;
  text-align: center;

  h1 {
    font-size: 3.8rem;
  }

  a {
    transition: all 0.2s ease;
    text-decoration: none;

    &:hover {
      color: ${gradStart};
    }

    &:focus {
      outline: none;
      transform: scale(1.05);
    }
  }

  .copyright {
    font-size: 1.2rem;
  }
`;

const FooterNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  a {
    color: white;
    margin: 2rem;
  }
`;

const _Footer = ({ title, icon }) => (
  <AnimatedContainer>
    <Footer>
      <FooterNav>
        <a
          href="https://storybrand.com/contact/"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us
        </a>
        <a
          href="https://thecustomeristhehero.com/live-sales-workshop-faqs/"
          target="_blank"
          rel="noreferrer"
        >
          Livestream FAQ
        </a>
        <a
          href="https://www.businessmadesimple.com/terms-and-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
      </FooterNav>
      <p className="copyright">
        Copyright © {new Date().getFullYear()} Donald Miller Words, LLC. All
        rights reserved
      </p>
      <Link to="/"></Link>
    </Footer>
  </AnimatedContainer>
);

// _Footer.propTypes = {
//   title: PropTypes.string.isRequired,
// };

export default _Footer;
