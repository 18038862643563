export const accent = '#f90000';

export const primaryText = '#090924';
export const purple = '#5B52E0';
export const offWhite = '#F5F5F9';
export const grey = '#E5E9EB';
export const subtleGrey = '#EBEBF2';
export const lightGrey = '#d3d3d3';
export const almostBlack = '#010101';
export const ssGreen = '#33d4a9';

export const gradStart = '#3faffd';
export const gradMid = '#259dfc';
export const gradEnd = '#6e56d7';

export const purpGradStart = '#534AC7';
export const purpGradEnd = '#5B52E0';

// Border radius
export const brNorm = '3rem';
export const brInner = '2rem';

// Spacing
export const rhythm = '2.5rem';
